import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import AddSchool from 'src/components/add-school/add-school';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const NewSchoolPage = () => {
  return (
    <Router basepath="/schools">
      <NewSchoolRoute path="/add" />
    </Router>
  );
};

const NewSchoolRoute: React.FC<RouteComponentProps> = (props) => {
  return (
    <Layout>
      <SEO title="Add School" />
      <PrivateRoute>
        <AddSchool {...props} />
      </PrivateRoute>
    </Layout>
  );
};

export default NewSchoolPage;
